import React, { useState } from 'react'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}))

const Capabilities = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setName] = useState(null)
  const [hoverText, setHoverText] = useState(null)

  const multi = [
    { name: 'book-launches', hoverText: 'Introduce author and book to reviewing press and media and those in topical vertical markets. plan and coordinate book signing events that generate maximum sales and coverage' },
    { name: 'branding', hoverText: 'Define solid positioning and value proposition for product or corporate message. cultivate consistent identification, definition and marketing via strategic planning and media coverage. focus on brand value and integrity to maximize awareness and market share' },
    { name: 'b2b', hoverText: 'Position company brand, key products and core messages that resonate with target industry and trade audiences' },
    { name: 'b2c', hoverText: 'Tailor messages to generate editorial coverage and drive consumer awareness' },
    { name: 'collateral', hoverText: 'Develop attention-getting newsletters, brochures, fliers, press kits, and websites with tailored content that target audiences look forward to receiving' },
    { name: 'community', hoverText: 'Leverage extensive national and local network to advance client objectives' },
    { name: 'corporate', hoverText: 'Build loyalty, pride and good will among company stakeholders including employees and their families, investors, management, vendors, and customers' },
    { name: 'crisis-planning', hoverText: 'Develop proactive strategies across all communications that safeguard and cultivate client brand reputation' },
    { name: 'event-planning', hoverText: 'Plan and coordinate events that embody a client’s key message, create a buzz among target audiences and drive media coverage' },
    { name: 'litigation', hoverText: 'Collaborate closely with client and counsel to implement a proactive plan that protects client brand equity with the public, key audiences and stakeholders' },
    { name: 'media-relations', hoverText: 'Develop, nurture and maintain media relationships built on mutual trust and credibility. analyze current client communication practices and provide recommendations to increase visibility. leverage media trends to maximize coverage' },
    { name: 'media-training', hoverText: 'Prepare client spokespersons for media contact to ensure clear, concise and consistent key messaging' },
    { name: 'message-strategy', hoverText: 'Assess target markets, competition, industry trends and publicity potential. accordingly, develop strategy and messaging to support and advance client brand' },
  ]

    const classes = useStyles()

    const handlePopoverOpen = (event, popoverId) => {
    // console.log({popoverId})
    const values = multi.filter((r) => r.name === popoverId )
    // console.log('values: ', values)
    setName(values[0].name)
    setHoverText(values[0].hoverText)  
    setAnchorEl(event.target)
  }

  const handlePopoverClose = () => { 
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl);

  return (
    <Layout bgcolor="#fec450">
      <SEO title="Capabilities" bgcolor="#fec450" />
      <h1>our capabilities</h1>
      <p>An unwavering standard of excellence at <strong>MKL Public Relations</strong> translates into dedicated, intelligent and creative strategies to meet client needs. Based in Salt Lake City, Utah, our agency provides a broad spectrum of PR services to companies ranging from corporate to home grown, technology to advertising, New York to Paris. Our integrated communications strategies help clients create and strengthen their brand images, while our ability to break through competitive “clutter” maximizes client positioning, market share, and top-of-mind recognition. Through targeted, proactive media campaigns, event planning, and community relations strategies and programs, <strong>MKL PR</strong> has generated coverage in major press and media across the country and abroad.</p>
      <h2>Key Competencies</h2>
      <ul>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'book-launches')}>Book Launches</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'branding')}>Branding</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'b2b')}>Business To Business Campaigns</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'b2c')}>Business To Consumer Campaigns</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'collateral')}>Collateral Communications</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'community')}>Community Relations</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'corporate')}>Corporate Relations, Internal</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'crisis-planning')}>crisis planning &amp; communications</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'event-planning')}>event planning &amp; management</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'litigation')}>litigation pr</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'media-relations')}>media relations</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'media-training')}>media training</Button></li>
        <li><Button onClick={(e) => handlePopoverOpen(e, 'message-strategy')}>message strategy, planning &amp; analysis</Button></li>
      </ul>

      <Popover
        id={name} open={open} anchorEl={anchorEl} onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom', horizontal: 'left'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Typography className={classes.typography} dangerouslySetInnerHTML={{ __html: hoverText }} style={{ display: 'block', maxWidth: '500px' }} />
      </Popover>

    </Layout>
  )
}

export default Capabilities
